// Parse HTML string and embedly elements with an embedded yotube video with the youtube-nocookie url
export const parseAndReplaceVideos = (htmlString: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  const elements: NodeListOf<HTMLAnchorElement> = tempDiv.querySelectorAll('p > a.embedly-card');

  elements.forEach(element => {
    const url = new URL(element.href);
    const videoId = url.searchParams.get('v');

    const replacementContainer = document.createElement('div');
    const videoElement = document.createElement('iframe');
    videoElement.width = '540px';
    videoElement.height = '310px';
    videoElement.src = `https://www.youtube-nocookie.com/embed/${videoId}`;

    replacementContainer.appendChild(videoElement);

    // Replace the existing DOM element with the React component
    if (element.parentNode && replacementContainer.firstChild) {
      element.parentNode.replaceChild(replacementContainer.firstChild, element);
    }
  });

  return tempDiv.innerHTML;
};
