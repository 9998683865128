import { useMemo } from 'react';
import { Box } from 'components/Box';
import { FlexContainer } from 'components/FlexContainer';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ExitIntentOverlay } from 'components/ExitIntentOverlay';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Container } from 'components/Container';

import { BlogPostContainer, ContainerInner, BlogHeroContainer } from 'components/Blog';

import { useScript } from 'hooks/useScript';
import { getDetails } from 'components/Blog/getDetails';
import { CampaignMonitorButton } from 'components/CampaignMonitorButton';
import { getParsedBlogPost } from 'helpers/getParsedBlogPost';
import { StyledHtml } from 'components/StyledHtml';
import { useExitIntent } from 'hooks/useExitIntent';
import { useCookiePreferences } from 'components/CookiePreferences/CookiePreferencesProvider';

import { parseAndReplaceVideos } from 'helpers/parseAndReplaceVideos';
import { isBrowser } from 'helpers/isBrowser';
import { PreviewCard } from 'components/PreviewCards';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { BlogPost, ContentfulBlogPostRaw } from 'types/blog/blogPost';
import type { BlogCategory } from 'types/blog/category';

const BlogPostPage: FC<
  LocalizedPageProps & {
    pageContext: {
      post: BlogPost;
      mostRecentPosts: ContentfulBlogPostRaw[];
      categories: BlogCategory[];
    };
  }
> = ({ pageContext: { post, mostRecentPosts, categories }, path }) => {
  const { showOverlay, handleClose } = useExitIntent('blog');
  const cookiePreferences = useCookiePreferences();

  const blogPosts = useMemo(
    () =>
      mostRecentPosts.map(blog => {
        const parsedBlogPost = getParsedBlogPost(blog);

        const details = getDetails({
          author: parsedBlogPost.author,
          date: parsedBlogPost.date,
        });

        return (
          <PreviewCard
            key={parsedBlogPost.id}
            categories={parsedBlogPost.categories}
            subtitle={details}
            urlSlug={parsedBlogPost.urlSlug}
            title={parsedBlogPost.title}
            description={parsedBlogPost.description}
            image={parsedBlogPost.image}
          />
        );
      }),
    [mostRecentPosts]
  );

  useScript('//cdn.embedly.com/widgets/platform.js');

  const blogPostContent =
    !cookiePreferences?.advertising && isBrowser
      ? parseAndReplaceVideos(post.content)
      : post.content;

  return (
    <Layout>
      {showOverlay && <ExitIntentOverlay handleClose={handleClose} />}

      <Seo blogPost={post} />
      <BlogHeroContainer currentPage={path} categories={categories} />
      <Container padding={15} color="white" verticalAlign="top" manageLayout={false}>
        <ContainerInner alignItems="unset">
          <BlogPostContainer padding={0} gap={2} manageLayout={false}>
            <FlexContainer justifyContent="space-between">
              <Box marginTop={2} paddingBottom={8}>
                <strong>
                  <Breadcrumbs crumb="Blog" crumbs={post.categories} />
                </strong>
              </Box>
              <CampaignMonitorButton />
            </FlexContainer>
            <h1>{post.title}</h1>
            <Box marginTop={2} paddingBottom={10}>
              <span>
                {getDetails({
                  author: post.author,
                  authorTitle: post.authorTitle,
                  authorCompany: post.authorCompany,
                  date: post.date,
                })}
              </span>
            </Box>
            <StyledHtml html={blogPostContent} />
          </BlogPostContainer>
          <FlexContainer direction="column">{blogPosts}</FlexContainer>
        </ContainerInner>
      </Container>
    </Layout>
  );
};
export default BlogPostPage;
